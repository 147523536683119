'use client';

import Image from 'next/image';
import { string } from 'prop-types';

import { useSchemes } from '@personly/libs-providers';

const CompanyImage = ({ alt }) => {
  const { schemeHandle } = useSchemes();

  const scheme = schemeHandle.toLowerCase();

  const src = `/images/personly-${scheme}.svg`;

  return <Image alt={alt} height={26} width={104} priority src={src} />;
};

CompanyImage.propTypes = { alt: string.isRequired };

export default CompanyImage;
